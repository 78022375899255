#login-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/images/landscape.jpg');
  background-size: cover;
  background-position: center;

  #login-form-card {
    width: 100%;
    max-width: 384px;
    border-radius: 24px;
    background: none;
    box-shadow: 38px 38px 77px rgba(0, 0, 0, 0.15), -38px -38px 77px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .ant-card-body,
    .ant-card-head {
      background: #ffffff;
    }

    .ant-card-actions {
      background: none;
      backdrop-filter: blur(16px);
      background: rgba(255, 255, 255, 0.3);
    }

    .ant-card-head-title {
      text-align: center;
      text-transform: uppercase;
    }
  }
}
