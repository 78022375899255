@keyframes notifications-active-animation {
  0% {
    background-color: #1da57a;
  }

  50% {
    background-color: #ff4d4f;
  }

  100% {
    background-color: #1da57a;
  }
}

.one-page-layout {
  min-height: 100vh;
}

#mainLayout {
  height: 100vh;

  &.layout-collapsed {
    #mainHeader {
      width: calc(100% - 48px);
    }

    #mainSidebarMenu {
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  #mainHeader {
    position: fixed;
    width: calc(100% - 224px);
    z-index: 1;
    box-shadow: 0 4px 25px -1px rgba(0, 0, 0, 0.23);
    height: 48px;
    line-height: 48px;
    display: flex;
    padding: 0 50px 0 0;

    .header-left {
      flex: 1;
    }

    .header-left,
    .header-right {
      display: flex;
      align-items: center;
    }

    .header-button {
      padding: 0 12px;
      height: 48px;
      border: none;
      margin: 0;
      color: white;
      border-radius: 0;

      .ant-avatar {
        margin-left: 8px;
      }

      &.header-button-action {
        padding: 0;
        width: 48px;
      }

      &:hover:not(.header-button-notifications) {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &.header-button-notifications {
        padding: 0;
        width: 48px;

        &.header-button-notifications-active {
          animation: notifications-active-animation infinite 3s;
        }
      }
    }
  }

  #mainSidebar {
    box-shadow: 0 4px 25px -1px rgba(0, 0, 0, 0.23);

    &,
    * {
      transition: 0.00000001s;
    }

    .logo-wrapper {
      padding: 5px;
    }

    .logo {
      img {
        height: 32px;
      }
      text-align: center;
      font-weight: 300;
      font-family: 'Pacifico', cursive;
      color: #ffffff;
      font-size: 24px;

      .logo-compact {
        display: none;
      }
    }

    &.ant-layout-sider-collapsed {
      .logo {
        text-align: center;

        .logo-wide {
          display: none;
        }
        .logo-compact {
          display: inline-block;
        }
      }
    }

    .sidebar-header {
      height: 48px;
      width: 200px;
    }
  }

  #mainSidebarMenu {
    overflow: auto;
    max-height: calc(100vh - 95px);

    &.ant-menu-inline-collapsed {
      width: unset;

      & > .ant-menu-item-group {
        & > .ant-menu-item-group-title {
          display: none;
        }

        & > .ant-menu-item-group-list {
          & > .ant-menu-item,
          & > .ant-menu-submenu > .ant-menu-submenu-title {
            padding: 4px 16px;
          }

          & > .ant-menu-item,
          & > .ant-menu-submenu .ant-menu-item {
            margin: 0;
            height: 48px;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }

    & > li.ant-menu-item,
    & > li.ant-menu-submenu > .ant-menu-submenu-title {
      padding: 4px 16px;
    }

    & > li.ant-menu-item,
    & > li.ant-menu-submenu .ant-menu-item {
      margin: 0;
      height: 48px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  #mainContent {
    margin: 8px;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    &.content-card-scrollable {
      overflow-x: unset;
      overflow-y: unset;
    }

    & > .ant-card-body {
      padding: 8px;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.content-breadcrumb {
  margin-bottom: 8px;
  padding: 4px 32px;
}

.ant-btn-nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
